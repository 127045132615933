import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Transition } from '@tailwindui/react';
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ClaimOfferForm from '@/components/forms/signIn/ClaimOfferForm';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import ClaimOfferFormModel, {
    claimOfferFormSchema,
} from '@/models/ClaimOfferFormModel';
import UpdateOfferFormModel, {
    updateOfferFormSchema,
} from '@/models/UpdateOfferFormModel';
import {
    ClaimOfferParams,
    UpdateOfferParams,
    claimOfferSignIn,
    updateOfferSignIn,
} from '@/services/authService';
import { setLoading } from '@/store/getStarted/getStartedLoadingSlice';
import { useUser } from '@/store/user/userSlice';
import { XIcon } from '../common/Icons';
import UpdateOfferForm from '../forms/signIn/UpdateOfferForm';
import ThreeDotsLoader from '../loaders/ThreeDotsLoader';

interface Props {
    open?: boolean;
    onClose(open: boolean): void;
    image?: string;
    title: string;
    offers: any;
}

export default function ModalClaimOffer({
    open,
    onClose,
    image,
    title,
    offers,
}: Props) {
    const [user] = useUser();
    const dispatch = useDispatch();
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [selectedCode, setSelectedCode] = useState<string>('');
    const { mutateAsync: claimOffer, isLoading: claimOfferLoading } =
        useMutation(async (payload: ClaimOfferParams) => {
            const { data, status } = await claimOfferSignIn(payload);
            if (status > 201) {
                throw Error('Email is incorrect');
            }
            return data;
        });

    const { mutateAsync: updateOffer, isLoading: updateOfferLoading } =
        useMutation(async (payload: UpdateOfferParams) => {
            const { data } = await updateOfferSignIn(
                user.subscription.id,
                payload
            );
            return data;
        });

    const handleSubmit = useHandleSubmit(
        claimOfferFormSchema,
        claimOffer,
        () => {
            dispatch(setLoading(false));
            setFormSuccess(true);
            setTimeout(() => {
                window.location.href = '/get-started';
            }, 3000);
        },
        dispatch
    );

    const handleUpdateSubmit = useHandleSubmit(
        updateOfferFormSchema,
        updateOffer,
        () => {
            dispatch(setLoading(false));
            setFormSuccess(true);
            setTimeout(() => {
                window.location.href = '/get-started';
            }, 3000);
        },
        dispatch
    );

    useEffect(() => {
        if (offers?.length === 1) {
            setSelectedCode(offers?.[0]?.code);
        }
    }, [offers]);

    return (
        <>
            <ThreeDotsLoader
                visible={claimOfferLoading || updateOfferLoading}
            />
            <AlertDialog.Root open={open} onOpenChange={onClose}>
                <AlertDialog.Portal>
                    <AlertDialog.Overlay className="fixed inset-0 z-[100] bg-black bg-opacity-25" />
                    <AlertDialog.Content
                        className={`fixed top-1/2 left-1/2 z-[101] m-auto flex h-auto max-h-[90vh] w-full max-w-[calc(100%_-_1rem)] -translate-y-2/4 -translate-x-2/4 transform flex-col overflow-y-auto overflow-x-clip border border-black bg-white text-left align-middle shadow-block transition-all md:w-fit md:max-w-[calc(100%_-_4rem)]
                        ${offers?.length <= 1 ? 'sm:w-fit' : 'md:w-fit'}`}
                    >
                        <AlertDialog.Title className="bg-black px-4 py-2 text-center text-lg font-medium leading-6 text-white">
                            {title}
                        </AlertDialog.Title>
                        <AlertDialog.Cancel
                            id={title}
                            className="absolute top-2 right-2"
                        >
                            <XIcon
                                className="h-6 w-6 text-white"
                                onClick={() => onClose(false)}
                            />
                        </AlertDialog.Cancel>
                        <div className="mt-2 min-h-0 flex-1 px-4 py-2">
                            {image && (
                                <img
                                    className="mb-2 h-auto w-full"
                                    src={image}
                                    alt="Claim ButcherCrowd's offer"
                                />
                            )}
                            <div className="flex min-h-[10rem] w-full items-center justify-center xs:px-8">
                                <Transition
                                    show={formSuccess}
                                    enter="transition-opacity transition-transform duration-600"
                                    enterFrom="opacity-0 translate-y-10"
                                    enterTo="opacity-100 translate-y-0"
                                >
                                    <div className="py-10 pt-6 text-center">
                                        <p className="mb-1 text-center text-lg font-medium uppercase">
                                            {selectedCode} promo applied!
                                        </p>
                                        <Link
                                            to="/get-started"
                                            className="mb-1 text-center text-xs uppercase text-primary underline hover:underline"
                                        >
                                            Make order & proceed to checkout
                                        </Link>
                                    </div>
                                </Transition>
                                {!formSuccess && (
                                    <div>
                                        <div
                                            className={`${
                                                offers?.length >= 3
                                                    ? 'md:mx-auto md:w-[calc(100%_-_15rem)]'
                                                    : ''
                                            }`}
                                        >
                                            <h3 className="space-y-1 text-center text-base font-bold uppercase leading-tight lg:space-y-2">
                                                Get free gifts in every order
                                                with Butchercrowd
                                            </h3>
                                            <p className="space-y-1 text-center text-base leading-tight lg:space-y-2">
                                                Select your welcome gift, and
                                                enter your email to activate
                                                your offer. <br />
                                                This will be applied at the
                                                checkout.
                                            </p>
                                        </div>
                                        {user?.subscription?.id ? (
                                            <Formik<UpdateOfferFormModel>
                                                initialValues={{
                                                    code:
                                                        offers?.length === 1
                                                            ? offers?.[0]?.code
                                                            : '',
                                                }}
                                                validationSchema={
                                                    updateOfferFormSchema
                                                }
                                                onSubmit={handleUpdateSubmit}
                                            >
                                                <UpdateOfferForm
                                                    selectedCode={selectedCode}
                                                    setSelectedCode={
                                                        setSelectedCode
                                                    }
                                                    offers={offers}
                                                />
                                            </Formik>
                                        ) : (
                                            <Formik<ClaimOfferFormModel>
                                                initialValues={{
                                                    email: '',
                                                    code:
                                                        offers?.length === 1
                                                            ? offers?.[0]?.code
                                                            : '',
                                                }}
                                                validationSchema={
                                                    claimOfferFormSchema
                                                }
                                                onSubmit={handleSubmit}
                                            >
                                                <ClaimOfferForm
                                                    selectedCode={selectedCode}
                                                    setSelectedCode={
                                                        setSelectedCode
                                                    }
                                                    offers={offers}
                                                />
                                            </Formik>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </AlertDialog.Content>
                </AlertDialog.Portal>
            </AlertDialog.Root>
        </>
    );
}
