import { InferType, object, string } from 'yup';

export const claimOfferFormSchema = object({
    email: string()
        .label('Email address')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Email address must be a valid email'
        )
        .required(),
    code: string().label('Code').required(),
});

type ClaimOfferFormModel = InferType<typeof claimOfferFormSchema>;

export default ClaimOfferFormModel;
